<template>
  <div class="contracts">
    <documents-header
      filters
      page_type="contracts"
      :set_file="setFile"
      :choose_files="chooseFiles"
      @date_range="updateDateRange"
    />
    <div class="table-components">
      <contracts-table
        :items="items"
        :is_loading="isLoading"
        @search="searchDocument"
        @remove_contract="removeContract"
      />
    </div>
    <pagination
      :skip="pagination.skip"
      :total="total"
      table_name="contracts"
      @change_pagination="handlerPagination"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Pagination from '@/views/shared/components/table/Pagination'
  import DocumentsHeader from '../../components/DocumentsHeader'
  import ContractsTable from '../../components/contracts/ContractsTable.vue'
  export default {
    name: 'Contracts',
    components: {
      DocumentsHeader,
      ContractsTable,
      Pagination
    },

    apollo: {
      ContractHeadList: {
        query: require('../../gql/ContractHeadList.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            input: {
              pagination: {
                skip: this.pagination.skip,
                take: this.pagination.take,
                search: this.pagination.search,
                supplier: this.getFilters?.supplier.map((item) => item.id) || [],
                fixed: this.getStatus,
                period: {
                  begin: this.data_range[0] ?? null,
                  end: this.data_range[1] ?? null
                }
              }
            }
          }
        },
        result({ data }) {
          this.items = data?.ContractHeadList.list ?? []
          this.total = data?.ContractHeadList.total ?? null
          this.isLoading = false
        },
        error(error) {
          this.isLoading = false
          console.error('Ошибка запроса: ', error)
        }
      }
    },

    data() {
      return {
        items: [],
        type: null,
        total: null,
        data_range: [],
        isLoading: true,
        pagination: {
          skip: 0,
          take: 20,
          search: ''
        }
      }
    },

    computed: {
      ...mapGetters({
        getFilters: 'contracts/getFilters'
      }),

      getStatus() {
        if (this.getFilters.status.length === 1) {
          return this.getFilters.status[0].id === 'fixed'
        }
        return null
      }
    },

    methods: {
      handlerPagination(option) {
        this.pagination.skip = (option.current_page - 1) * option.take
        this.pagination.take = option.take
      },

      searchDocument(data) {
        this.pagination.search = data
        this.pagination.skip = 0
        this.pagination.take = 20
        this.$apollo.queries.ContractHeadList.refetch()
      },

      removeContract() {
        this.pagination.skip = 0
        this.pagination.take = 20
        this.$apollo.queries.ContractHeadList.refetch()
      },

      updateDateRange(date) {
        this.data_range = date
        this.$apollo.queries.ContractHeadList.refresh()
      },

      chooseFiles(type) {
        this.type = type
        document.getElementById('fileUpload').click()
      },

      async setFile(e) {
        this.file = e.target.files[0]
        // this.$bvModal.show('excel-import')
      }
    }
  }
</script>

<style scoped lang="scss">
  .contracts {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .table-components {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }
</style>
