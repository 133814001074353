var render = function render(){
  var _vm$search;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-block"
  }, [_c('div', {
    staticClass: "table-block__content"
  }, [_c('div', {
    staticClass: "search-block"
  }, [_c('b-form', {
    staticClass: "pos-r",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.handlerSearch.apply(null, arguments);
      }
    }
  }, [_c('img', {
    staticClass: "search-block__img",
    attrs: {
      "src": "/img/icons/contract/search.svg",
      "alt": ""
    }
  }), _c('b-form-input', {
    staticClass: "search-block__input",
    attrs: {
      "type": "text",
      "placeholder": "Поиск"
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), ((_vm$search = _vm.search) === null || _vm$search === void 0 ? void 0 : _vm$search.length) > 0 ? _c('b-button', {
    staticClass: "search-block__btn-clear-search-str",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.clearSearch.apply(null, arguments);
      }
    }
  }) : _vm._e()], 1), _c('b-dropdown', {
    staticClass: "ml-3",
    attrs: {
      "no-caret": "",
      "variant": "none"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "btn-more"
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/settings.svg",
            "alt": ""
          }
        })])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    on: {
      "click": _vm.showSortModal
    }
  }, [_vm._v(" Изменить столбцы ")])], 1)], 1), _c('div', {
    staticClass: "table"
  }, [_c('resizable-table', {
    ref: "table_contracts",
    staticClass: "table-contracts",
    attrs: {
      "table_name": "table_contracts",
      "items": _vm.items,
      "default_fields": _vm.fields,
      "empty_text": "Пусто",
      "resizable": true,
      "busy": _vm.is_loading
    },
    on: {
      "row-click": _vm.rowClick
    },
    scopedSlots: _vm._u([{
      key: "head_id",
      fn: function () {
        return [_c('e-checkbox', {
          attrs: {
            "checked": _vm.select_all
          },
          on: {
            "click": _vm.changeSelectAll
          }
        })];
      },
      proxy: true
    }, {
      key: "body_id",
      fn: function (_ref) {
        var _vm$selected;

        var item = _ref.item;
        return [_c('div', {
          staticClass: "b-status",
          class: {
            success: item === null || item === void 0 ? void 0 : item.fixed
          }
        }), _c('div', {
          on: {
            "click": function ($event) {
              $event.stopPropagation();
            }
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "checked": (_vm$selected = _vm.selected) === null || _vm$selected === void 0 ? void 0 : _vm$selected.some(function (el) {
              return el === (item === null || item === void 0 ? void 0 : item.id);
            }),
            "position": "center"
          },
          on: {
            "change": function (val) {
              return _vm.addSelected(val, item === null || item === void 0 ? void 0 : item.id);
            }
          }
        })], 1)];
      }
    }, {
      key: "body_source",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('ext-system-logo', {
          key: item.id,
          attrs: {
            "ext_system": {
              type: item === null || item === void 0 ? void 0 : item.source
            }
          }
        })];
      }
    }, {
      key: "body_numAndDate",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "table-text-left"
        }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.number))]), _c('div', {
          staticClass: "item-date table-text-left"
        }, [_vm._v(_vm._s(_vm.convertDate(item === null || item === void 0 ? void 0 : item.date)))])];
      }
    }, {
      key: "body_supplier",
      fn: function (_ref4) {
        var _item$supplier, _item$supplier2, _item$supplier3, _item$supplier4, _item$supplier5;

        var item = _ref4.item;
        return [_c('div', {
          staticClass: "entity-name"
        }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : (_item$supplier = item.supplier) === null || _item$supplier === void 0 ? void 0 : _item$supplier.name))]), _c('div', {
          staticClass: "entity-info-block"
        }, [item !== null && item !== void 0 && (_item$supplier2 = item.supplier) !== null && _item$supplier2 !== void 0 && _item$supplier2.name ? _c('div', [_vm._v("ИНН: " + _vm._s(item === null || item === void 0 ? void 0 : (_item$supplier3 = item.supplier) === null || _item$supplier3 === void 0 ? void 0 : _item$supplier3.inn))]) : _vm._e(), item !== null && item !== void 0 && (_item$supplier4 = item.supplier) !== null && _item$supplier4 !== void 0 && _item$supplier4.name ? _c('div', [_vm._v("КПП: " + _vm._s(item === null || item === void 0 ? void 0 : (_item$supplier5 = item.supplier) === null || _item$supplier5 === void 0 ? void 0 : _item$supplier5.kpp))]) : _vm._e()])];
      }
    }, {
      key: "body_name",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "table-name-contract"
        }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.name))])];
      }
    }, {
      key: "body_entity",
      fn: function (_ref6) {
        var _item$entity, _item$entity2, _item$entity3, _item$entity4, _item$entity5;

        var item = _ref6.item;
        return [_c('div', {
          staticClass: "entity-name"
        }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : (_item$entity = item.entity) === null || _item$entity === void 0 ? void 0 : _item$entity.name))]), _c('div', {
          staticClass: "entity-info-block"
        }, [item !== null && item !== void 0 && (_item$entity2 = item.entity) !== null && _item$entity2 !== void 0 && _item$entity2.name ? _c('div', [_vm._v("ИНН: " + _vm._s(item === null || item === void 0 ? void 0 : (_item$entity3 = item.entity) === null || _item$entity3 === void 0 ? void 0 : _item$entity3.inn))]) : _vm._e(), item !== null && item !== void 0 && (_item$entity4 = item.entity) !== null && _item$entity4 !== void 0 && _item$entity4.name ? _c('div', [_vm._v("КПП: " + _vm._s(item === null || item === void 0 ? void 0 : (_item$entity5 = item.entity) === null || _item$entity5 === void 0 ? void 0 : _item$entity5.kpp))]) : _vm._e()])];
      }
    }, {
      key: "body_operation",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_c('div', {
          staticClass: "operation"
        }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.operation))])];
      }
    }, {
      key: "body_period",
      fn: function (_ref8) {
        var item = _ref8.item;
        return [item !== null && item !== void 0 && item.begin && item !== null && item !== void 0 && item.end ? _c('div', {
          staticClass: "period-block"
        }, [_c('div', [_vm._v(_vm._s(_vm.convertDate(item === null || item === void 0 ? void 0 : item.begin)))]), _c('div', [_vm._v("-")]), _c('div', [_vm._v(_vm._s(_vm.convertDate(item === null || item === void 0 ? void 0 : item.end)))])]) : _c('div', {
          staticClass: "period-block"
        }, [_vm._v(" Бессрочный ")])];
      }
    }, {
      key: "body_fixed",
      fn: function (_ref9) {
        var item = _ref9.item;
        return [item !== null && item !== void 0 && item.fixed ? _c('div', {
          staticClass: "table-status-true"
        }, [_vm._v(" Проведен ")]) : _c('div', {
          staticClass: "table-status-false"
        }, [_vm._v(" Черновик ")])];
      }
    }, {
      key: "body_user",
      fn: function (_ref10) {
        var _item$user, _item$user2, _item$user3;

        var item = _ref10.item;
        return [_c('div', {
          staticClass: "user-block"
        }, [_c('div', [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : (_item$user = item.user) === null || _item$user === void 0 ? void 0 : _item$user.last_name))]), _c('div', [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : (_item$user2 = item.user) === null || _item$user2 === void 0 ? void 0 : _item$user2.first_name))]), _c('div', [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : (_item$user3 = item.user) === null || _item$user3 === void 0 ? void 0 : _item$user3.second_name))])])];
      }
    }])
  }), _c('custom-navbar', {
    staticClass: "table-navbar",
    attrs: {
      "items": _vm.selected,
      "item_name": "Позиция"
    },
    on: {
      "clear": _vm.clearSelected
    },
    scopedSlots: _vm._u([{
      key: "buttons",
      fn: function () {
        return [_c('b-button', {
          attrs: {
            "variant": "dark"
          },
          on: {
            "click": _vm.removeContract
          }
        }, [_vm._v(" Удалить ")])];
      },
      proxy: true
    }])
  })], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }