var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "contracts"
  }, [_c('documents-header', {
    attrs: {
      "filters": "",
      "page_type": "contracts",
      "set_file": _vm.setFile,
      "choose_files": _vm.chooseFiles
    },
    on: {
      "date_range": _vm.updateDateRange
    }
  }), _c('div', {
    staticClass: "table-components"
  }, [_c('contracts-table', {
    attrs: {
      "items": _vm.items,
      "is_loading": _vm.isLoading
    },
    on: {
      "search": _vm.searchDocument,
      "remove_contract": _vm.removeContract
    }
  })], 1), _c('pagination', {
    attrs: {
      "skip": _vm.pagination.skip,
      "total": _vm.total,
      "table_name": "contracts"
    },
    on: {
      "change_pagination": _vm.handlerPagination
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }