<template>
  <div class="table-block">
    <div class="table-block__content">
      <div class="search-block">
        <b-form
          class="pos-r"
          @submit.prevent="handlerSearch"
        >
          <img
            class="search-block__img"
            src="/img/icons/contract/search.svg"
            alt=""
          />

          <b-form-input
            v-model="search"
            type="text"
            class="search-block__input"
            placeholder="Поиск"
          />
          <b-button
            v-if="search?.length > 0"
            class="search-block__btn-clear-search-str"
            @click.prevent="clearSearch"
          />
        </b-form>

        <b-dropdown
          class="ml-3"
          no-caret
          variant="none"
        >
          <template #button-content>
            <div class="btn-more">
              <img
                src="/img/icons/settings.svg"
                alt=""
              />
            </div>
          </template>
          <b-dropdown-item @click="showSortModal"> Изменить столбцы </b-dropdown-item>
        </b-dropdown>
      </div>

      <div class="table">
        <resizable-table
          ref="table_contracts"
          class="table-contracts"
          table_name="table_contracts"
          :items="items"
          :default_fields="fields"
          empty_text="Пусто"
          :resizable="true"
          :busy="is_loading"
          @row-click="rowClick"
        >
          <template #head_id>
            <e-checkbox
              :checked="select_all"
              @click="changeSelectAll"
            />
          </template>

          <template #body_id="{ item }">
            <div
              class="b-status"
              :class="{ success: item?.fixed }"
            ></div>
            <div @click.stop>
              <b-form-checkbox
                :checked="selected?.some((el) => el === item?.id)"
                position="center"
                @change="(val) => addSelected(val, item?.id)"
              />
            </div>
          </template>

          <template #body_source="{ item }">
            <ext-system-logo
              :key="item.id"
              :ext_system="{ type: item?.source }"
            />
          </template>

          <template #body_numAndDate="{ item }">
            <div class="table-text-left">{{ item?.number }}</div>
            <div class="item-date table-text-left">{{ convertDate(item?.date) }}</div>
          </template>

          <template #body_supplier="{ item }">
            <div class="entity-name">{{ item?.supplier?.name }}</div>
            <div class="entity-info-block">
              <div v-if="item?.supplier?.name">ИНН: {{ item?.supplier?.inn }}</div>
              <div v-if="item?.supplier?.name">КПП: {{ item?.supplier?.kpp }}</div>
            </div>
          </template>

          <template #body_name="{ item }">
            <div class="table-name-contract">{{ item?.name }}</div>
          </template>

          <template #body_entity="{ item }">
            <div class="entity-name">{{ item?.entity?.name }}</div>
            <div class="entity-info-block">
              <div v-if="item?.entity?.name">ИНН: {{ item?.entity?.inn }}</div>
              <div v-if="item?.entity?.name">КПП: {{ item?.entity?.kpp }}</div>
            </div>
          </template>

          <template #body_operation="{ item }">
            <div class="operation">{{ item?.operation }}</div>
          </template>

          <template #body_period="{ item }">
            <div
              v-if="item?.begin && item?.end"
              class="period-block"
            >
              <div>{{ convertDate(item?.begin) }}</div>
              <div>-</div>
              <div>{{ convertDate(item?.end) }}</div>
            </div>
            <div
              v-else
              class="period-block"
            >
              Бессрочный
            </div>
          </template>

          <template #body_fixed="{ item }">
            <div
              v-if="item?.fixed"
              class="table-status-true"
            >
              Проведен
            </div>
            <div
              v-else
              class="table-status-false"
            >
              Черновик
            </div>
          </template>

          <template #body_user="{ item }">
            <div class="user-block">
              <div>{{ item?.user?.last_name }}</div>
              <div>{{ item?.user?.first_name }}</div>
              <div>{{ item?.user?.second_name }}</div>
            </div>
          </template>

          <!-- <template #body_comment="{ item }">
            <div class="comment">{{ item?.comment }}</div>
          </template> -->
        </resizable-table>

        <custom-navbar
          class="table-navbar"
          :items="selected"
          item_name="Позиция"
          @clear="clearSelected"
        >
          <template #buttons>
            <b-button
              variant="dark"
              @click="removeContract"
            >
              Удалить
            </b-button>
          </template>
        </custom-navbar>
      </div>
    </div>
  </div>
</template>

<script>
  import formatDate from '@/utils/formatDate'
  import ResizableTable from '@/components/ResizableTable.vue'
  import CustomNavbar from '@/views/core/sidebar/components/CustomNavbar'
  import ExtSystemLogo from '@/views/operational-processes/components/incoming/ExtSystemLogo'
  export default {
    name: 'ContractsTable',

    components: {
      ResizableTable,
      CustomNavbar,
      ExtSystemLogo
    },

    props: {
      items: {
        type: Array,
        default: () => []
      },
      is_loading: {
        type: Boolean,
        default: true
      }
    },

    data() {
      return {
        search: '',
        select_all: false,
        selected: [],
        fields: [
          {
            key: 'id',
            checked: true,
            label: '',
            isRowHeader: true,
            width: 50
          },
          {
            key: 'source',
            checked: true,
            label: 'Тип',
            // sortable: true,
            width: 80
          },
          {
            key: 'numAndDate',
            checked: true,
            label: '№ / Дата',
            width: 140
          },
          {
            key: 'supplier',
            checked: true,
            label: 'Поставщик',
            width: 235
          },
          {
            key: 'name',
            checked: true,
            label: 'Наименование',
            width: 235
          },
          {
            key: 'entity',
            checked: true,
            label: 'Организация',
            width: 235
          },
          {
            key: 'operation',
            checked: true,
            label: 'Операция док-та',
            width: 200
          },
          {
            key: 'period',
            checked: true,
            label: 'Период действия',
            width: 200
          },
          {
            key: 'fixed',
            checked: true,
            label: 'Статус',
            width: 150
          },
          {
            key: 'user',
            checked: true,
            label: 'Менеджер',
            width: 235
          }
          // {
          //   key: 'comment',
          //   checked: true,
          //   label: 'Коментарий',
          //   width: 235
          // }
        ]
      }
    },

    watch: {
      items(newItems) {
        let currentSelectedAfterPagination = newItems.filter((item) => {
          return this.selected.some((el) => item.id === el.id)
        })
        if (this.items.length === 0) {
          this.select_all = false
        } else if (currentSelectedAfterPagination.length === this.items.length) {
          this.select_all = true
        } else {
          this.select_all = false
        }
      }
    },

    methods: {
      handlerSearch() {
        this.$emit('search', this.search)
      },

      async removeContract() {
        try {
          await this.$apollo.mutate({
            mutation: require('../../gql/DeleteContractHead.gql'),
            variables: {
              ids: this.selected
            }
          })
          this.$noty.show(`Удалено`)
          this.clearSelected()
          this.$emit('remove_contract')
        } catch (err) {
          console.log(err)
          this.$noty.error('Ошибка')
        }
      },

      rowClick(item) {
        this.$router.push({
          name: `operation-process.overhead.contracts.document.edit`,
          params: { id: item?.id }
        })
      },

      changeSelectAll() {
        this.select_all = !this.select_all
        if (!this.select_all) {
          this.items.forEach((obj) => (this.selected = this.selected.filter((item) => obj.id !== item)))
          return
        }

        let arrayCurrenSelected = []
        this.items.forEach((item) => {
          const id = item.id
          if (!this.selected.some((obj) => obj === id)) {
            arrayCurrenSelected.push(item.id)
          }
        })
        this.selected = [...this.selected, ...arrayCurrenSelected]
      },

      addSelected(val, idItem) {
        if (val) this.selected = [...this.selected, idItem]
        else {
          this.selected = this.selected?.filter((el) => el !== idItem)
        }
        let currentSeleted = this.items.filter((item) => {
          return this.selected.some((el) => item.id === el)
        })
        if (currentSeleted.length < this.items.length) this.select_all = false
        if (currentSeleted.length === this.items.length) this.select_all = true
      },

      clearSearch() {
        this.search = ''
      },

      clearSelected() {
        this.select_all = false
        this.selected = []
      },

      convertDate(date) {
        if (date) return formatDate(new Date(date), 'numeric')
      },

      showSortModal() {
        this.$bvModal.show('sort-table-modal')
      }
    }
  }
</script>

<style scoped lang="scss">
  .table-text-left {
    text-align: left;
  }
  .table-navbar {
    position: absolute;
    bottom: 10px;
    right: 20px;
    left: 20px;
  }

  .navbar-dropdown {
    &__item-description {
      color: var(--text-secondary-gray-400, #888);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
    }
  }

  .table-block {
    background: #fff;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    &__content {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: hidden;
    }
  }

  :deep .logo-cont {
    justify-content: center;
  }
  .table {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    overflow: scroll;

    :deep th {
      height: 60px;
      color: var(--text-secondary-gray-400, #888) !important;
    }
  }

  .b-status {
    width: 2px;
    height: 32px;
    position: absolute;
    left: 0px;
    top: 50%;
    background: #bcbcbc;
    border-radius: 0px 1px 1px 0px;
    transform: translate(0%, -50%);

    &.success {
      background: #00cb91;
    }
  }

  .table-status-true {
    width: 91px;
    padding: 0px 12px;
    border-radius: 3px;
    background: #ebf9f5;
    color: #00cb91;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }

  .table-status-false {
    // padding: 0px 12px;
    width: 114px;
    border-radius: 3px;
    background: var(--gray-gray-50, #efefef);
    color: var(--text-secondary-gray-400, #888);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }

  .search-block {
    display: flex;
    justify-content: flex-start;
    background-color: #fff;
    padding-left: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    position: relative;
    border: 1px solid var(--gray-gray-150, #e1e1e1);
    border-radius: 2px;
    border-bottom: none;

    &__img {
      position: absolute;
      z-index: 10;
      top: 10px;
      left: 10px;
    }

    &__input {
      border-radius: 2px;
      border: 1px solid #bcbcbc;
      width: 300px;
      height: 32px;
      padding-left: 28px;
    }
    &__input::placeholder {
      color: var(--text-placeholder-gray-300, #bcbcbc);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &__btn-clear-search-str {
      position: absolute;
      top: 9px;
      right: 9px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      overflow: hidden;
      background: #efefef;
      padding: 0;

      &:before,
      &:after {
        content: '';
        height: 1px;
        width: 8px;
        background: #888888;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%) rotate(-45deg);
      }

      &:after {
        transform: translate(-50%) rotate(45deg);
      }
    }
  }

  .item-date {
    color: var(--text-secondary-gray-400, #888);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .entity-name {
    text-align: left;
  }
  .entity-info-block {
    display: flex;
    gap: 7px;
    color: var(--text-secondary-gray-400, #888);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .table-name-contract {
    text-align: left;
  }
  .operation {
    text-align: left;
  }

  .period-block {
    display: flex;
    gap: 7px;
    text-align: left;
  }

  .user-block {
    display: flex;
    gap: 5px;
    text-align: left;
  }

  .comment {
    text-align: left;
  }
</style>
